<template>
  <div style="min-height: 100%;" class="container">
    <van-nav-bar 
      title="店铺详情" 
      left-arrow 
      @click-left="goBack" 
      fixed 
      placeholder />

    <div class="travelShop">
      <van-image
        width="40"
        height="40"
        fit="cover"
        :src="shopInfo.imgUrl"/>

      <span class="travelShop-shopName">{{ shopInfo.name }}</span>

      <div class="travelShop-service" @click="contact">
        <van-icon name="service-o" size="20" />
        <span>客服</span>
      </div>
    </div>

    <van-list 
      v-model="isLoading" 
      :finished="finished" 
      finished-text="没有更多了" 
      @load="onLoad">
      <div class="shopList">
        <div class="shopList-item" v-for="x in records" :key="x.id">
          <van-image width="100%" height="150px" lazy-load radius="5" fit="cover" :src="x.picUrl" />

          <div class="shopList-item-main">
            <div class="jiageTy" v-for="(y, i) in x.virtualGoodsPayNmae" :key="y.type">
              <span>{{ y.name }}:</span>
              <span style="color: #E54320;">{{ y.jine }}</span>
              <span>{{ y.danwei }}</span>
              <span style="margin: 0 5px;" v-if="x.virtualGoodsPayNmae.length - 1 != i">|</span>
            </div>
          </div>

          <div class="shopList-item-footer">
            <div class="shopList-item-footer-btn">
              <span>已售{{ x.saleNum }}件</span>
              <span>
                <van-button 
                  round type="info" 
                  size="mini" 
                  color="#E54320" 
                  @click="enterDetail(x.id)">购买</van-button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>

import { collectShop } from '@/api/detail'
import { getTravelListPage } from '@/api/Ge/port'
import { contact } from "@/common"
import { Toast } from 'vant'

export default {
  data() {
    return {
      records: [],
      gradeList: [],
      shopInfo: {},
      userCollect: '0',
      loading: false,
      isLoading: false,
      finished: false,
      params: {
        current: 1,
        size: 10
      },
      coupons: [],
      riceivedCoupons: [],
      ticketBoolean: false,
    }
  },

  created() {
    if (this.$route.query.session) {
      this.$store.commit('setSession', this.$route.query.session)
    }
    this.shopInfo = this.$route.query
  },

  mounted () {
    let screenWidth = document.documentElement.getBoundingClientRect().width
    document.documentElement.style.fontSize = (screenWidth / 10) + 'px'
  },

  methods: {
    contact () {
      if (this.shopInfo.imAccount) {
        contact(this.shopInfo.imAccount)
      } else {
        Toast('暂无客服')
      }
    },

    getStoreDetail () {
      let params = JSON.parse(JSON.stringify(this.params))
      params.shopId = this.$route.query.shopId
      getTravelListPage(params).then(res => {
        if (res.data.code === 0) {
          res.data.data.records.forEach(x => {
            let virtualGoodsPay = x.virtualGoodsPay.split(',')
            let virtualGoodsPayNmae = []
            virtualGoodsPay.includes('1') ? virtualGoodsPayNmae.push({ type: 1, name: '金叶子', jine: x.goldLeaf, danwei: '个' }) : ''
            virtualGoodsPay.includes('2') ? virtualGoodsPayNmae.push({ type: 2, name: '驿马', jine: x.postHorse, danwei: '匹' }) : ''
            virtualGoodsPay.includes('3') ? virtualGoodsPayNmae.push({ type: 3, name: '官凭', jine: x.officialCertificate, danwei: '个' }) : ''
            virtualGoodsPay.includes('4') ? virtualGoodsPayNmae.push({ type: 4, name: '现金', jine: x.salesPrice, danwei: '元' }) : ''
            x.virtualGoodsPayNmae = virtualGoodsPayNmae
          })
          if (res.data.data.records.length) {
            this.records = [...this.records, ...res.data.data.records]
            this.isLoading = false
          } else {
            this.finished = true
          }
        }
      })
    },

    enterDetail (id) {
      this.$router.replace({
        path: '/travel',
        query: {
          id,
          session: this.$route.query.session
        }
      })
    },

    goBack () {
      this.$router.go(-1)
    },

    collectShop () {
      this.loading = true
      let data = {
        type: '2',
        relationId: this.shopInfo.id
      }
      collectShop(data).then(res => {
        if (res.data.code === 0) {
          this.loading = false
          this.getStoreDetail()
        }
      })
    },

    onLoad () {
      this.getStoreDetail()
      this.params.current++
    },

    lingqv () {
      this.ticketBoolean = true
    },

    updatePage () {
      this.getStoreDetail()
    },

    closeTicket () {
      this.ticketBoolean = false
    }
  }
}
</script>

<style lang="less" scoped>
.van-hairline--bottom::after {
  border-bottom-width: 0;
}
/deep/ .van-nav-bar {
  .van-nav-bar__left {
    padding: 0 5px;
  }
}
.container {
  background: #f6f6f6;
  .van-list {
    flex: 1;
  }
}
.travelShop {
  display: flex;
  align-items: center;
  padding: 10px;
  background: #fff;
  &-shopName {
    flex: 1;
    margin-left: 10px;
    font-size: 14px;
  }
  &-service {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }
}
.shopList {
  width: 96%;
  height: 100%;
  margin: 10px auto;
  &-item {
    background: #FEFAE9;
    padding: 0.2703rem;
    margin-bottom: 0.2703rem;
    box-sizing: border-box;
    border-radius: 0.1351rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-main {
      font-size: 16px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      .jiageTy {
        display: flex;
        align-items: center;
      }
    }
    &-footer {
      &-btn {
        font-size: 0.3243rem;
        color: #999;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .van-button--mini {
        padding: 0 10px;
      }
    }
  }
}
</style>